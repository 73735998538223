import React from "react";
import clsx from "clsx";
const Column = ({ children, className, style, ...props }) => {
  return (
    <div className={clsx("px-3", className)} style={style} {...props}>
      {children}
    </div>
  );
};

export default Column;
