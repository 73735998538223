import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// grid
import Container from "../components/grid/container";
import Row from "../components/grid/row";
import Column from "../components/grid/column";

// images
import yellow from "../assets/yellomini.svg";
import blue from "../assets/bluemini.svg";

import ybig from "../assets/big.svg";
import bbig from "../assets/bbig.svg";

// subheader
import date from "../assets/date.svg";
import location from "../assets/location.svg";

// components
import SocialLinks from "../components/social-links/social-links.component";
import clsx from "clsx";

// panel1 images
import cimbir from "../assets/panels/LilianaCimbir.jpg";
//import gorea from "../assets/panels/AnaGorea.jpeg";
import natalia from "../assets/panels/natalia.png";
import daniela from "../assets/panels/daniela.png";
import cristina from "../assets/panels/cristina.png";

import covriga from "../assets/panels/IrinaCovriga.jpg";
import hanganu from "../assets/panels/AdrianHanganu.jpg";
import gorea from "../assets/panels/AnaGorea.jpeg";
import russu from "../assets/panels/AngelicaRussu.jpg";

import muntean from "../assets/panels/DanaMuntean.jpg";
import curosu from "../assets/panels/DoruCurosu.jpg";
//import creavenco from "../assets/panels/EcaterinaCravcenco.jpeg";
import zubcov from "../assets/panels/EcaterinaZubcov.jpg";

import boico from "../assets/panels/EugenBoico.jpeg";
import pociumbanu from "../assets/panels/IrinaPociumbanu.jpg";
import morgoci from "../assets/panels/LidiaMorgoci.jpg";
import frant from "../assets/panels/LiliaFrant.jpeg";

import siomina from "../assets/panels/LiudmilaSiomina.jpg";
//import golubas from "../assets/panels/MariaGolubas.jpg";
//import caraulan from "../assets/panels/MariaCaraulan.jpg";
import taranu from "../assets/panels/OleseaTaranu.jpg";

import barbanoua from "../assets/panels/ViorelBarbanoua.jpg";
import strajescu from "../assets/panels/IrinaStrajescu.png";
import turcan from "../assets/panels/VladaTurcan.png";

// panel2 images
import kardo from "../assets/panels/kardo.png";

// workshop1
import vicky from "../assets/panels/vicky.png";
// ws2
import stefan from "../assets/panels/stefan.png";
import alex from "../assets/panels/alex.png";

import nicoleta from "../assets/panels/nicoleta.png";
import mariana from "../assets/panels/mariana.png";
import doina from "../assets/panels/doina.png";
// logos

import img1 from "../assets/logos/1.svg";
import img2 from "../assets/logos/2.svg";
import img3 from "../assets/logos/3.svg";
import img4 from "../assets/logos/4.svg";
import img5 from "../assets/logos/5.svg";
import img6 from "../assets/logos/6.svg";
import img7 from "../assets/logos/7.svg";
import img8 from "../assets/logos/8.svg";
import img9 from "../assets/logos/9.svg";
import img10 from "../assets/logos/10.svg";
import img11 from "../assets/logos/11.svg";
import img12 from "../assets/logos/12.svg";
import img13 from "../assets/logos/13.svg";
import img14 from "../assets/logos/14.svg";
import img15 from "../assets/logos/15.svg";
import img16 from "../assets/logos/16.svg";

// Social Media
import ogImage from "../assets/helmet/preview.jpg";

import { Helmet } from "react-helmet";

const logoData = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
];


const informations = [
  {
    img: date,
    text: "30 Noiembrie, 13:00 MD",
    link: "https://facebook.com/events/s/forum-mentorme-i-urmatorul-pas/1311517113631627/",
  },
  {
    img: location,
    text: "Glia Impact Hub",
    link: "https://maps.app.goo.gl/ZxTnQB1acxxPHD2AA",
  },
];

const agendaData = [
  {
    main: "Înregistrarea participanților",
    time: "13:00 - 13:30",
  },
  {
    main: "Cuvânt de salut - Irina Covriga (Președinta MentorMe), Ana Gorea (Vicepreședinta Consiliului Asociației Femeilor Antreprenoare din Moldova), Dana Muntean (Fondatoare MentorMe), ",
    time: "13:30 - 13:45",
  },
  {
    main: "Oportunități pentru carieră în Republica Moldova - \
    Irina Covriga (Președinta MentorMe), \
    Adrian Hanganu (Project Manager departamentului Educație Mediacor, COR), \
    Lilia Franț (Vicepreședinta Confederaţiei Naţionale a Sindicatelor din Moldova), \
    Irina Strajescu (Director Oameni și Comunicare Moldcell si Directoare Executiva Fundația Moldcell), \
    Liliana Cimbir (Chief Operating Officer Dreamups)",
    time: "13:45 - 14:10",
  },
  {
    main: "Storytrelling: Reintegrarea tinerilor în câmpul muncii din Republica Moldova, după studii peste hotare - \
    Vlada Țurcan (Tânăra care a ales să revină acasă din diaspora. Manager de comunicare MentorMe)",
    time: "14:10 - 14:40",
  },
  {
    main: "AO Motivație - Case de succes - Ecaterina Zubcov (Specialistă în Suport pentru Angajare, AO Motivație)",
    time: "14:40 - 14:50",
  },
  {
    main: "Employee Branding: Viitorul carierei tale începe cu tine - Doru Curosu (Trainer și Specialist HR)",
    time: "14:50 - 15:35",
  },
  {
    main: "Coffee break",
    time: "15:35 - 16:05",
  },
  {
    main: "Provocările în carieră: cum să înfrunți frica, stresul și burnout-ul din perspectiva psihologiei - \
    Ludmila Siomina-Ghițu (Psiholog cu 25 de ani de experiență, trainer de business, co-fondator al PsychologyProf), \
    Angelica Russu (Psihoterapeută sistemică de cuplu și familie, expertă în prevenirea burnout-ului), \
    Olesea Țăranu (Psiholog școlar, cadru didactic dedicat dezvoltării personale)",
    time: "16:05 - 16:35",
  },
  {
    main: "Noi nu suntem o familie, noi suntem o echipă de fotbal. Team Management: Subiectiv. Onest. #tătdinviață - Viorel Barbanoua (CEO, PRofile Agency)",
    time: "16:35 - 17:05",
  },
  {
    main: "Speedmatching Session",
    time: "17:05 - 17:35",
  },
  {
    main: "De la haos la ordine - descoperă secretul organizării eficiente! - \
    Lidia Morgoci (Manager de Proiecte, Consultant în atragere de fonduri nerambursabile, Trainer dezvoltare personală la locul de muncă)", // Viorel Barbănouă
    time: "17:35 - 18:05",
  },
  {
    main: "Gândire de lider - Eugen Boico (Director General Publicis Moldova)",
    time: "18:05 - 18:35",
  },
  {
    main: "Cuvânt de încheiere - Irina Covriga (Președinta MentorMe)",
    time: "18:35 - 18:45",
  },
];

const members = [
  {
    name: 'Liliana Cimbir',
    role: 'Dreamups',
    imageUrl: cimbir,
  },
  {
    name: 'Liliana Cimbir',
    role: 'Dreamups',
    imageUrl: gorea,
  }
]

const panelsData = [
  {
    title: "Panel 1",
    margin: true,
    description: "Oportunități pentru tineri în Republica Moldova: Cum să te realizezi în industriile creative, IT și antreprenoriat?",
    images: [
      {
        img: cimbir,
        isEmail: true,
        linkedIn: "dr.iraidasnigur@gmail.com",
        name: "Liliana Cimbir",
        description: "Dreamups",
      },
      {
        img: gorea,
        linkedIn: "https://www.linkedin.com/in/michelle-iliev-945a096a/",
        name: "Irina Covriga",
        description: "MentorMe",
      },
      {
        img: natalia,
        linkedIn: "https://mecc.gov.md/ro/content/natalia-griu",
        name: "Adrian Hanganu",
        description:
          "COR",
      },
      {
        img: daniela,
        linkedIn: "https://www.linkedin.com/in/dpetrusevschi/",
        name: "Lilia Franț",
        description: "Sindicat Muncii",
      },
      {
        img: cristina,
        linkedIn: " https://www.linkedin.com/in/cristina-voroneanu-0614a2162/",
        name: "Irina Strajescu",
        description:
          "Moldcell, TBC",
      },
    ],
  },
  {
    title: "Panel 2",
    margin: true,
    description: " Forța de muncă a viitorului: Lecțiile pandemiei Covid-19",
    images: [
      {
        img: kardo,
        linkedIn: "https://www.linkedin.com/in/kardo-sharifi-9044981a3/",
        name: "Kardo Sharifi",
        description: "Project Manager, Dreamups",
      },
      {
        img: nicoleta,
        linkedIn: "https://www.linkedin.com/in/nicoletastogu/",
        name: "Nicoleta Stogu",
        description: "Specialist recrutare, Endava",
      },
      {
        img: mariana,
        linkedIn: " https://www.linkedin.com/in/mariana-rufa-3423275b/",
        name: "Mariana Rufa",
        description: "Director Executiv, European Business Association",
      },
      {
        img: doina,
        linkedIn: "https://www.linkedin.com/in/doinachiselita/",
        name: "Moderatoare: Doina Chiseliță",
        description:
          "Consultant în tehnologii financiare în cadrul EY. Moderatoare",
      },
    ],
  },
  {
    title: "Workshop 1",
    description: "Încadrarea tinerilor pe piața muncii de la A la Z",
    images: [
      {
        img: vicky,
        linkedIn: "https://www.linkedin.com/in/victoriaulinici/",
        name: "Vicky Ulinici",
        description: "People Engagement & Development Manager",
      },
    ],
  },
  {
    title: "Workshop 2",
    description: "Inteligența emoțională între conștientizare și reacție",
    images: [
      {
        img: stefan,
        linkedIn: "https://www.linkedin.com/in/popov-stefan-b3b6a0199",
        name: "Ștefan Popov",
        description: "Psiholog, psihoterapeut și cercetător",
      },
    ],
  },
  {
    title: "Workshop 3",
    description: "„Vinde-mi acest pix” sau Arta persuasiunii pentru fiecare",
    images: [
      {
        img: alex,
        isEmail: true,
        linkedIn: "constantinov.alexander@gmail.com ",
        name: "Alexandru Constantinov",
        description: "Alexander Constantinov – Trainer și Expert în vânzări",
      },
    ],
  },
];

const IndexPage = () => {
  return (
    <Container>
      <Helmet
          title={"MentorMe.md"}
          titleTemplate={`%s | mentorme.md`}
          meta={[
            {
              name: "description",
              content:
                "Conectăm și ajutăm gratuit tinerii să-și urmeze studiile universitare, să-și accelereze dezvoltarea profesională și să identifice noi oportunități de afaceri.",
            },
            {
              property: "og:title",
              content: "MentorMe - Primul program de mentorat din Moldova",
            },
            {
              property: "og:description",
              content:
                "Conectăm și ajutăm gratuit tinerii să-și urmeze studiile universitare, să-și accelereze dezvoltarea profesională și să identifice noi oportunități de afaceri.",
            },
            {
              property: "og:type",
              content: "website",
            },
            {
              property: `og:url`,
              content: `https://www.mentorme.md/`,
            },
            {
              property: `og:image`,
              content: ogImage,
            }
          ]}
        />
      <section>
        <img
          src={ybig}
          alt="decorative"
          className="absolute top-0 left-0 hidden md:block md:h-3/6 lg:h-4/6 xl:h-5/6"
        />
        <img
          src={bbig}
          alt="decorative"
          className="absolute top-0 right-0  hidden md:block md:w-2/12"
        />
        <img
          src={yellow}
          alt="decorative"
          className="absolute top-0 left-0 w-3/12 h-5/12 md:hidden"
        />
        <img
          src={blue}
          alt="decorative"
          className="absolute top-0 right-0 w-3/12 md:hidden"
        />
        <SocialLinks />
        <div className="py-4 sm:py-8 lg:pb-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            
            <div className="mt-16 flow-root sm:mt-24">
              <div className="-m-2 bg-gray-900/5 p-2 lg:-m-4 lg:p-4">
                <a href="https://www.facebook.com/events/1311517113631627">
                <img
                  alt="Event preview"
                  src={ogImage}
                  width={1920}
                  height={1080}
                  className="rounded-md"
                />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*<h1 className="text-center font-bold text-blue-title md:mt-18 sm:mt-18 mt-18 text-h1m sm:text-h1t lg:text-h1d">
          MentorMe Forum
        </h1>
        <h2 className="text-center text-h2m sm:text-h2t md:text-h2d font-bold mt-6 text-pink">
          Următorul pas în carieră
        </h2>
        <Row className="justify-center gap-10 mt-20">
          {informations.map((data) => (
            <a href={data.link}>
              <Column className="flex items-center">
                <img
                  src={data.img}
                  alt={data.text}
                  className="h-5 w-5 sm:h-auto sm:w-auto"
                />

                <span className="ml-6 text-sm md:text-h1m">{data.text}</span>
              </Column>
            </a>
          ))}
          </Row>*/}

        <div className="px-6 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {/*<a
              href="https://forms.gle/4RXpNcz1tWVfA9wD8"
              className="rounded-md bg-black-600 px-3.5 py-2.5 text-sm md:text-h2m text-pink font-semibold 
              hover:bg-black-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black-600"
            >
              Înregistrare
            </a>
            <a href="https://facebook.com/events/s/forum-mentorme-i-urmatorul-pas/1311517113631627/" 
              className="text-sm md:text-h2m font-semibold text-blue-title">
              mai multe detalii <span aria-hidden="true">→</span>
        </a>*/}
        <a href="https://forms.gle/4RXpNcz1tWVfA9wD8">
        <button
        type="button"
        className="rounded-md bg-pink px-6 py-3 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Înregistrare
      </button></a>
      <a href="https://facebook.com/events/s/forum-mentorme-i-urmatorul-pas/1311517113631627/">
      <button
        type="button"
        className="rounded-md bg-blue-title px-6 py-3 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        mai multe detalii
      </button>
      </a>
          </div>
        </div>
      </div>
        
        <div className="mt-24 md:mt-32">
          <h2 className="text-center text-h2m sm:text-h2t md:text-h3 font-bold mt-6 text-pink">
            Agenda
          </h2>
          <div className="mt-6 mb-6 md:mt-14 rounded-lg">
            {agendaData.map((data, index) => (
              <Row
                className={clsx(
                  index + 1 !== agendaData.length && "mb-6",
                  "w-full mx-auto "
                )}
              >
                <Column className="bg-blue-title py-5 pl-8 mx-auto w-10/12 rounded-xl">
                  {/*<strong className="text-white text-span">{data.time} </strong>*/}
                  <strong className="text-white text-span">{index+1 + ") "}</strong>
                  <span className="text-white font-thin text-span">
                    {data.main}
                  </span>
                </Column>
              </Row>
                ))}
          </div>
        </div>
      </section>

      <section className="mt-24 md:mt-14">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Cuvânt de salut</h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Irina Covriga',
              role: 'Președinta MentorMe',
              imageUrl: covriga,
            },{
              name: 'Ana Gorea',
              role: 'Vicepreședinta Consiliului Asociației Femeilor Antreprenoare din Moldova',
              imageUrl: gorea,
            },{
              name: 'Dana Muntean',
              role: 'Fondatoare MentorMe',
              imageUrl: muntean,
            }].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="mt-24 md:mt-14">
        {/*panelsData.map((data) => (
          <div className="mt-24 md:mt-14">
            <div className="md:mb-24 mb-16">
              <h3 className="text-h2m sm:text-h2t md:text-h3 mb-6 md:mb-10 font-bold text-pink">
                {data.title}
              </h3>
              <span className="md:text-h1m text-span text-blue-restBlue">
                {data.description}
              </span>
            </div>
            <Row className={clsx(data.margin && "", "flex justify-center")}>
              {data.images.map((image, index) => (
                <Column
                  className={clsx(
                    index !== 2 && data.margin && "xl:mr-16",
                    index === 4 && "xl:mr-0",

                    "flex flex-col items-center justify-center mt-14"
                  )}
                >
                  <a
                    href={
                      image.isEmail
                        ? `mailto:${image.linkedIn}`
                        : image.linkedIn
                    }
                    className="text-center"
                  >
                    <img src={image.img} alt="" className="mx-auto" />
                  </a>
                  <div className="flex flex-col mt-4">
                    <span className="text-h2m text-blue-restBlue max-w-xs">
                      {image.name}
                    </span>
                    <span className="text-span text-blue-restBlue max-w-xs mt-2">
                      {image.description}
                    </span>
                  </div>
                </Column>
              ))}
            </Row>
          </div>
                  ))*/}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Panel 1</h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              Oportunități pentru carieră în Republica Moldova
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Irina Covriga',
              role: 'Președinta MentorMe',
              imageUrl: covriga,
            },{
              name: 'Adrian Hanganu',
              role: 'Project Manager departamentului Educație Mediacor, COR',
              imageUrl: hanganu,
            },{
              name: 'Lilia Franț',
              role: 'Vicepreședinta Confederaţiei Naţionale a Sindicatelor din Moldova',
              imageUrl: frant,
            },{
              name: 'Irina Strajescu',
              role: 'Director Oameni și Comunicare Moldcell si Directoare Executiva Fundația Moldcell',
              imageUrl: strajescu,
            },{
              name: 'Liliana Cimbir',
              role: 'Chief Operating Officer Dreamups',
              imageUrl: cimbir,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="mt-24 md:mt-14">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Prezentare</h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              Storytrelling: Reintegrarea tinerilor în câmpul muncii din Republica Moldova, după studii peste hotare
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Vlada Țurcan',
              role: 'Tânăra care a ales să revină acasă din diaspora. Manager de comunicare MentorMe',
              imageUrl: turcan,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
        </section>

      <section className="mt-24 md:mt-14">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Intervenție</h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              AO Motivație - Case de succes
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Ecaterina Zubcov',
              role: 'Specialistă în Suport pentru Angajare, AO Motivație',
              imageUrl: zubcov,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="mt-24 md:mt-14">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Prezentare</h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              Employee Branding: Viitorul carierei tale începe cu tine
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Doru Curoșu',
              role: 'Trainer și Specialist HR',
              imageUrl: curosu,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="mt-24 md:mt-14">
        
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Panel 2</h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              Provocările în carieră: cum să înfrunți frica, stresul și burnout-ul din perspectiva psihologiei
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Ludmila Siomina-Ghițu',
              role: 'Psiholog cu 25 de ani de experiență, trainer de business, co-fondator al PsychologyProf',
              imageUrl: siomina,
            }, {
              name: 'Olesea Țăranu',
              role: 'Psiholog școlar, cadru didactic dedicat dezvoltării personale',
              imageUrl: taranu,
            }, {
              name: 'Angelica Russu',
              role: 'Psihoterapeută sistemică de cuplu și familie, expertă în prevenirea burnout-ului',
              imageUrl: russu,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="mt-24 md:mt-14">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl"></h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              Noi nu suntem o familie, noi suntem o echipă de fotbal. Team Management: Subiectiv. Onest. #tătdinviață
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Viorel Barbănouă',
              role: 'CEO, PRofile Agency',
              imageUrl: barbanoua,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="mt-24 md:mt-14">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl"></h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              De la haos la ordine - descoperă secretul organizării eficiente!
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Lidia Morgoci',
              role: 'Manager de Proiecte, Consultant în atragere de fonduri nerambursabile, Trainer dezvoltare personală la locul de muncă',
              imageUrl: morgoci,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      

      <section className="mt-24 md:mt-14">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl"></h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              Gândire de lider
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Eugen Boico',
              role: 'Director General Publicis Moldova',
              imageUrl: boico,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="mt-24 md:mt-14 mb-14">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl"></h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              Cuvânt de încheiere
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Irina Covriga',
              role: 'Președinta MentorMe',
              imageUrl: covriga,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="mt-24 md:mt-14 mb-14">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl"></h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {[{
              name: 'Irina Pociumbanu',
              role: 'Moderator',
              imageUrl: pociumbanu,
            },].map((person) => (
              <li key={person.name}>
                <img alt="" src={person.imageUrl} className="mx-auto size-24 rounded-full" />
                <h3 className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm/6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

    </Container>
  );
};

export default IndexPage;
