import React from "react";
import Row from "../grid/row";
import Column from "../grid/column";

// images
import facebook from "./assets/facebook.svg";
import ig from "./assets/ig.svg";
import yt from "./assets/yt.svg";
const data = [
  {
    image: facebook,
    alt_text: "Go to facebook",
    link: "https://facebook.com/events/s/forum-mentorme-i-urmatorul-pas/1311517113631627/",
    id:"facebook_event"
  },
  {
    image: yt,
    alt_text: "Go to YouTube",
    link: "https://www.youtube.com/channel/UCwVvehu5MUXjh6SFKCtvN-g",
    id:"youtube_channel"
  },
  {
    image: ig,
    alt_text: "Go to Instagram",
    link: "https://www.instagram.com/mentorme_md/",
    id:"instagram_account"
  },
];

const SocialLinks = () => {
  return (
    <Row className={"justify-center mt-12"}>
      {data.map((data) => {
        return (
          <Column id={data.id}>
            <a href={data.link}>
              <img src={data.image} alt={data.alt_text} />
            </a>
          </Column>
        );
      })}
    </Row>
  );
};

export default SocialLinks;
